<template>
  <div>
    <div style="padding:20px">
      <!-- 搜索框 -->
      <div style="display:flex;margin-bottom:20px">
        <el-input placeholder="请输入学生姓名" class="inPut" v-model="query.studentName"></el-input>
        <el-input placeholder="请输入手机号" class="inPut" v-model="query.studentPhone"></el-input>
        <el-select
            v-model="query.coachId"
            filterable
            placeholder="请输入或选择老师(教练)"
            remote
            :remote-method="selectCoach">
          <el-option
              v-for="(item) in coachList"
              :key="item.coachid"
              :label="item.coachname"
              :value="item.coachid"
          />
        </el-select>

        <el-button type="primary" @click="getData()">搜索</el-button>

        <el-button type="info" @click="reset">重置</el-button>
      </div>
      <!-- 表格 -->
      <el-table
          ref="studentListTable"
          :data="studentList"
          border
          height="610px"
          :row-style="{height: '54px'}"

      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="学生ID" prop="id"/>
        <el-table-column label="学生姓名" prop="studentName"/>
        <el-table-column label="手机号" prop="studentPhone" min-width="110"/>
        <el-table-column label="学校" prop="studentSchool"/>
        <el-table-column label="年级" prop="studentClass"/>
        <el-table-column label="届" prop="studentGrade"/>
        <el-table-column label="家长ID" prop="userId"/>
        <el-table-column label="家长姓名" prop="userName"/>
        <el-table-column label="教练ID" prop="coachId"/>
        <el-table-column label="所属教练" prop="coachName"/>
        <el-table-column label="学员状态" prop="orderStateName"/>
        <el-table-column label="时间" prop="createTime" min-width="160"/>

        <el-table-column label="操作">
          <template #default="scope">
            <el-button v-if="scope.row.coachId===null" @click="setStudentToMe(scope.row)">拉入</el-button>
            <el-button v-else @click="setStudentToOther(scope.row,$event)">分配</el-button>
          </template>
        </el-table-column>

      </el-table>
      <!-- 分页 -->
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="current"
            :total="total"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>

<!--      批量操作项-->
<!--      <div style="margin-top: 20px">
        <el-button @click="toggleSelection([studentList[1], studentList[2]])">切换第二、第三行的选中状态</el-button>
        <el-button @click="toggleSelection()">取消选择</el-button>
      </div>-->
      <!-- 分配学员弹框 -->
      <el-dialog v-model="showSetStudentToDialog" title="分配学员" width="60%"
                 :before-close="handleClose">
        <el-form>
          <el-row>
            <el-col :span="10">
              <el-form-item label="学生接收人" label-width="120px">
                <el-select
                    v-model="editCoachForm.coachId"
                    filterable
                    placeholder="请输入或选择人员"
                    remote
                    :remote-method="selectCoach">
                  <el-option
                      v-for="item in coachList"
                      :key="item.coachid"
                      :label="item.coachname"
                      :value="item.coachid"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <template #footer>
          <el-button @click="showSetStudentToDialog=false">
            取消
          </el-button>
          <el-button @click="doSetStudentCoach" type="primary">
            确定
          </el-button>
        </template>
      </el-dialog>
    </div>

  </div>
</template>

<script>
import {allocationStudent, selectPageStudent} from "@/api/studentList";
import {selectDictByType} from "@/api/dict";
import {listCoach} from "@/api/framework";

export default {
  name: "studentList",
  data() {
    return {
      query: {
        coachId: '',
        coachName: "",
        pageIndex: 1,
        pageSize: 10,
        studentName: "",
        studentPhone: ""
      },
      studentList: [],
      total: null,
      current: null,
      deptList: [],
      thisStateList: [],
      coachList: [],
      editCoachForm: {
        coachId: null,
        studentIds: []
      },
      showSetStudentToDialog: false,
    }
  },
  methods: {
    handlePageChange(e) {
      console.log("分页点击...." + e)
      if (this.current !== e) {
        this.query.pageIndex = e
        this.current = e
        this.getData()
      }
    },
    //加载列表数据
    getData() {
      //请求列表接口
      selectPageStudent(this.query).then(res => {
        if (res.code === 200) {
          this.studentList = res.data.records
          this.total = res.data.total
          this.current = res.data.current
          this.query.pageIndex = res.data.current
          console.log("返回的current" + this.current)
        } else {
          this.$message.error('获取数据失败!')
        }
      })
    },
    //初始化列表搜索前 基础数据
    async getInitData() {
      //获取初始的客户状态
      await selectDictByType('custThisState').then(res => {
        if (res.code === 200) {
          this.thisStateList = res.data
        } else {
          this.$message.error('获取意向客户当前状态失败')
        }
      })
      //加载列表数据
      this.getData()

      //空值查一下数据
      this.selectCoach('')
    },
    //检索 教练的姓名
    selectCoach(e) {
      listCoach(e).then(res => {
        if (res.code === 200) {
          this.coachList = res.data
        } else {
          this.$message.error('')
        }
      })
    },
    // 重置搜索条件
    reset() {
      this.query.coachId = ''
      this.query.coachName = ''
      this.query.studentName = ''
      this.query.studentPhone = ''
      this.query.pageIndex = 1
      this.getData()
    },
    //弹框关闭按钮前 触发
    handleClose(e) {
      console.log(e);
      this.showSetStudentToDialog = false
    },
    //点击确定分配执行
    doSetStudentCoach() {
      console.log("点击确定执行")

      //调用 分配给指定人员 接口
      allocationStudent(this.editCoachForm).then(res => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '分配学生操作成功!'
          });
          this.showSetStudentToDialog = false
          //加载列表数据
          this.getData()
        } else {
          this.$message({
            type: 'warning',
            message: '分配学生操作失败!'
          });
        }
      })
    },
    //设置学生给我
    setStudentToMe(row, e) {
      this.editCoachForm.studentIds = []
      this.editCoachForm.studentIds.push(row.id)
      //分配给自己
      this.editCoachForm.coachId = -1

      console.info('点击拉取');
      console.info(row);
      console.info(e);
      this.$confirm('确认将该学员拉入我的学员库中吗?', '提示:', {
        confirmButtonText: '确定拉入',
        cancelButtonText: '我再想想',
        type: 'warning'
      }).then(() => {
        //调用 分配给自己的操作接口
        allocationStudent(this.editCoachForm).then(res => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '拉入操作成功!'
            });
            this.showSetStudentToDialog = false
            this.editCoachForm.coachId = null
            //加载列表数据
            this.getData()
          } else {
            this.$message({
              type: 'warning',
              message: '分配学生操作失败!'
            });
          }
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消拉入操作'
        });
      });
    },
    //列表中点击分配触发
    setStudentToOther(row, e) {
      this.editCoachForm.studentIds = []
      this.editCoachForm.studentIds.push(row.id)
      this.editCoachForm.coachId = row.coachName
      console.info('点击分配');
      console.info(row);
      console.info(e);
      this.showSetStudentToDialog = true
    },
    //切换选中
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.studentListTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.studentListTable.clearSelection();
      }
    },
    //取消选中
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
  //当前组件的dom和vm实例初始化 完毕后
  created() {
    console.log("执行了声明周期")
    this.getInitData()

  }
}
</script>

<style scoped>
.inPut {
  margin: 0 10px;
  width: 260px
}

.mx-1 {
  margin: 5px;
}

.mr10 {
  margin-right: 10px;
}
</style>