import request from "@/utils/request";

/**
 * 获取 学员信息列表 -学员公海和所有 数据
 * @param param
 * @returns {*}
 */
export const selectPageStudent = (param) => {
    return request({
        url: '/sys/studentRecord/selectPageStudent',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};

/**
 * 分配学生
 * @param param
 * @returns {*}
 */
export const allocationStudent = (param) => {
    return request({
        url: '/sys/studentRecord/allocation',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};